/**
 * Import des librairies
 */
import 'zone.js';
import 'codemirror/addon/fold/foldgutter';
import 'codemirror/addon/fold/brace-fold';
import 'codemirror/lib/codemirror';
import 'codemirror/addon/edit/closebrackets';
import 'codemirror/addon/edit/matchbrackets';
import 'codemirror/addon/lint/lint';
import 'codemirror/addon/lint/json-lint';
import 'codemirror/addon/merge/merge';

/**
 * Déclaration d'une variable globale (pour les WebSockets)
 */
(window as any).global = window;